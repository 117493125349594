




/**
 * The Version Control protocol
 */
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import User from '@/models/User';
import ArchiveComponent from '@/components/shared/Archive.component.vue';
import { VersionControl } from '@/misc/VersionControl';

const UserStore = namespace('user');

@Component({
  components: {ArchiveComponent},
})
export default class UserArchiveComponent extends Vue {

  @UserStore.Action('loadUserOrigin')
  private loadUserOrigin!: (userId: string) => Promise<User[]>;

  @Prop()
  private user!: User;

  @Watch('user')
  public onUserChange() {
    this.loadArchive();
  }

  private userVersions: User[] = [];
  private loaded: boolean = false;

  public mounted() {
    this.loadArchive();
  }

  public async loadArchive() {
    this.loaded = false;
    this.userVersions = await this.loadUserOrigin(this.user.id!);
    this.userVersions.sort((vc1, vc2) => VersionControl.compareLogDate(vc1, vc2));
    this.userVersions.push(this.user);
    this.loaded = true;
  }
}
