











































































































































































import {Component, Vue} from 'vue-property-decorator';
import User from '@/models/User';
import {namespace} from 'vuex-class';
import Throttle from '@/helper/Throttle';
import UserTimeTrackingComponent from '@/components/user/UserTimeTracking.component.vue';
import TimeTrackingFilterComponent from '@/components/time-tracking/TimeTrackingFilter.component.vue';
import UserBaseDataComponent from '@/components/user/UserBaseData.component.vue';
import {WorkSessionFilterData} from '@/helper/WorksessionFilterData';
import moment, {Moment} from 'moment';
import Customer from '@/models/Customer';
import Location from '@/models/Location';
import UserArchiveComponent from '@/components/user/UserArchive.component.vue';
import RJTabs from '@/components/shared/custom-vuetify/RJTabs.vue';
import RJTextField from '@/components/shared/custom-vuetify/RJTextField.vue';
import {TabItem} from '@/interfaces/TabItem';
import {Permission} from '@/misc/enums/permission.enum';

const UserStore = namespace('user');
const CustomerStore = namespace('customer');
const JobStore = namespace('job');

@Component({
  computed: {
    Permission() {
      return Permission;
    },
  },
  components: {
    RJTextField,
    RJTabs,
    UserArchiveComponent,
    UserTimeTrackingComponent,
    TimeTrackingFilterComponent,
    UserBaseDataComponent,
    UserTimeSheetComponent: () => import(
      '@/components/user/UserTimeSheet.component.vue'),
    UserManageComponent: () => import(
      /* webpackChunkName: "UserManageComponent" */
      '@/components/user/UserManage.component.vue'),
    UploadDataComponent: () => import(
      '@/components/shared/UploadFile.component.vue'),
  },
})
export default class UserDetailsView extends Vue {

  @UserStore.Action('loadUserAction')
  private loadUserAction!: (userId: string) => Promise<User>;
  @UserStore.Action('resendInvitationAction')
  private resendInvitationAction!: (user: User) => Promise<void>;
  @UserStore.Getter('activeUser')
  private _user!: User;
  @UserStore.Mutation('storeActiveUser')
  private storeActiveUser!: (user: User | null) => void;
  @UserStore.Mutation('storeActiveUser')
  private activeUserMutation!: (user: User) => any;
  @UserStore.Action('deleteUserAction')
  public deleteUserAction!: (user: User) => Promise<User>;
  @CustomerStore.Action('loadCustomersAction')
  private loadCustomersAction!: (companyId: string) => Promise<Customer[]>;
  @CustomerStore.Action('loadCustomerAction')
  private loadCustomerAction!: (companyId: string) => Promise<Customer>;
  public companyId!: string;
  @UserStore.Action('editUserAction')
  private editUserAction!: (user: User) => Promise<User>;

  get user(): User {
    return this._user;
  }

  @CustomerStore.Getter('customers')
  private _customers!: Customer[];

  /**
   * visual values
   */
  private throttle!: Throttle;
  private THROTTLE_DELAY: number = 500;
  public tabsModel: number = 0;
  public showManageUserSideCard = false;
  public showDeleteUserDialog = false;
  private showOTPDialog: boolean = false;


  /**
   * filter values
   */
  private dateStartPickerValue: string = '';
  private dateEndPickerValue: string = '';
  private filterHasComment: boolean = false;
  private filterHasImages: boolean = false;
  private customers: Customer[] | null = [];
  private currentFilter!: WorkSessionFilterData;
  public currentTimeFilter: string = 'month';
  // string for the OTP generation
  public oneTimePassword: string = '';
  /**
   * Current Date selected date, default is today's date.
   */
  public currentDate!: Moment;
  /**
   * Current Week, default is this week of the year
   */
  public currentWeek: number = 0;
  /**
   * Current year, default this year
   */
  public currentYear: number = 0;
  /**
   * Current Month, default is the this month of the year
   */
  public currentMonth: number = 0;
  /**
   * Max weeks of current year
   */
  public maxWeeks!: number;
  /**
   * All available Customer Locations
   */
  public customerLocations: Location[] = [];
  /**
   * Current Selected Locations
   * in the filter settings
   */
  public currentObjects: Location[] | null = [];


  public async mounted() {
    this.companyId = this.$route.params.companyId;
    try {
      // reset activeUser store entry to avoid wrong user loading bug
      this.storeActiveUser(null);
      // load all data that is required for this view
      await this.loadUserAction(this.$route.params.userId);
      this.throttle = new Throttle(this.THROTTLE_DELAY);
      await this.loadCustomersAction(this.companyId);
    } catch (e: any) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }


    this.currentFilter = {
      company: this.companyId,
      endTimeAtFrom: moment().startOf('month').toISOString(),
      endTimeAtTo: moment().endOf('month').toISOString(),
      users: [this.user.id!],
    };
    // Load all necessary relations like customers, users and object locations
  }

  private createPassword() {
    this.showOTPDialog = true;
  }

  public get getTabItems(): TabItem[] {
    return [
      {
        key: 'basedata',
        text: this.$t('USER_DETAIL.TABS.BASE_DATA').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.USER_READ_OWN),
      }, {
        key: 'timetracking',
        text: this.$t('GENERAL.TIME_TRACKING').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.USER_READ_OWN),
      }, {
        key: 'timesheet',
        text: this.$t('USER_DETAIL.TABS.TIMESHEET').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.USER_READ_OWN),
      },
      {
        key: 'documents',
        text: this.$t('USER_DETAIL.TABS.DOCUMENTS').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.USER_READ_OWN),
       },
      {
        key: 'archive',
        text: this.$t('VERSION_CONTROL.ARCHIVE').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.USER_READ_OWN),
      },
    ];
  }

  /**
   * Resend the invitation link
   */
  public async resendInvitation() {
    this.throttle.throttle(async () => {
      try {
        await this.resendInvitationAction(this.user!);
        this.$notifySuccessSimplified('USER_DETAIL.NOTIFICATIONS.INVITATION_RESEND.SUCCESS');
      } catch (e: any) {
        if (e.status === 429) { // too error code for many mails were sent
          e.data.resendAllowedAt = this.$options.filters!.toDateTime(e.data.resendAllowedAt); // format date
          this.$notifyErrorSimplified(`USER_DETAIL.NOTIFICATIONS.INVITATION_RESEND.${e.status}`, e.data, 10000);
        } else {
          this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
        }
      }
    });
  }

  /**
   * create a new OTP for a user without an accepted invite
   */
  public async replacePassword() {
    this.oneTimePassword = Math.random().toString(36).substr(2, 8);
    try {
      const userCopy = User.parseFromObject(this.user.parseToObject());
      userCopy.oneTimePassword = this.oneTimePassword;
      // delete cleanTimes. They should not be updated. They cause an error anyway if filled with values
      delete userCopy.plannedCleanTimes;
      delete userCopy.availableAtCleanTimes;
      await this.editUserAction(userCopy);
      this.showOTPDialog = false;
      this.$notifySuccessSimplified('LOGIN.NOTIFICATIONS.RESET_PASSWORD.OTP_SUCCESS');
    } catch (e: any) {
      this.$notifyErrorSimplified('LOGIN.NOTIFICATIONS.RESET_PASSWORD.OTP_ERROR');
    }
  }

  public onEditUserClick() {
    this.showManageUserSideCard = true;
  }

  public onDeleteUserClick() {
    this.showDeleteUserDialog = true;
  }

  public async onUserDelete() {
    this.showDeleteUserDialog = false;
    try {
      await this.deleteUserAction(this.user);
      this.$notifySuccessSimplified('USER_MANAGE.NOTIFICATIONS.USER_DELETE.SUCCESS');
      // if successful
      await this.$router.push({
        name: 'usersOverview', params: {
          companyId: this.$route.params.companyId,
        },
      });
    } catch (e: any) {
      this.$notifyErrorSimplified('USER_MANAGE.NOTIFICATIONS.USER_DELETE.ERROR');
    }
  }

  private getTabSize(): number {
    if (this.tabsModel === 1) {
      return 10;
    } else {
      return 12;
    }
  }

  private onTimeFilterUpdated(timeFilter: string) {
    this.currentTimeFilter = timeFilter;

    // Reset Values if TimeFilter was changed
    const today = moment();
    this.currentDate = today;
    this.currentYear = today.year();
    this.maxWeeks = today.isoWeeksInYear();
    this.currentWeek = today.week();
    this.currentMonth = today.month() + 1;
  }

  public getCurrentFilterSettings(): WorkSessionFilterData {

    // Build Filter Object
    const filter = {...this.currentFilter};

    // Set Customers to Filter
    filter.customers = this.customers && this.customers.length > 0
      ? this.customers!.map((customer) => customer.id!)
      : null;

    // Set Locations to Filter
    filter.locations = this.currentObjects && this.currentObjects.length > 0
      ? this.currentObjects!.map((location) => location.id!)
      : null;

    // individual time frame
    if (this.isIndividual) {
      const fromDate = moment.utc(this.dateStartPickerValue);
      filter.endTimeAtFrom = fromDate.startOf('day').toISOString();

      // Check if End Date was Set
      // if not use today's date
      const toDate = this.dateEndPickerValue.length > 0
        ? moment.utc(this.dateEndPickerValue)
        : moment();

      // Set Created To Date
      filter.endTimeAtTo = toDate.endOf('day').local().toISOString();
    }

    // Selected day as Timeframe
    if (this.isDaily) {
      const startOfDay = moment(this.currentDate).startOf('day');
      const endOfDay = moment(this.currentDate).endOf('day');
      filter.endTimeAtFrom = startOfDay.toISOString();
      filter.endTimeAtTo = endOfDay.toISOString();
    }

    // Selected Month as Timeframe
    if (this.isMonthly) {
      // Get Current Week
      const month = moment().set('month', this.currentMonth - 1).set('year', this.currentYear);

      // Get start and end of Week
      const startOfMonth = moment(month).startOf('month');
      const endOfMonth = moment(month).endOf('month');

      // Set From and To for current filter
      filter.endTimeAtFrom = startOfMonth.toISOString();
      filter.endTimeAtTo = endOfMonth.toISOString();
    }

    // Selected Week as Timeframe
    if (this.isWeekly) {
      // Get Current Week
      const week = moment().set('year', this.currentYear).set('week', this.currentWeek);

      // Get start and end of Week
      const startOfWeek = moment(week).startOf('week');
      const endOfWeek = moment(week).endOf('week');

      // Set From and To for current filter
      filter.endTimeAtFrom = startOfWeek.toISOString();
      filter.endTimeAtTo = endOfWeek.toISOString();
    }

    filter.hasComments = this.filterHasComment;
    filter.hasImages = this.filterHasImages;

    return filter;
  }

  /**
   * Listener for Date Value Changes
   * sets the end and start picker values
   * e.g. for displaying at the top
   */
  public onDateValuesChanged(dates: { dateStart: string, dateEnd: string }) {
    this.dateEndPickerValue = dates.dateEnd;
    this.dateStartPickerValue = dates.dateStart;
  }

  /**
   * Event Listener for Misc Boolean changes
   */
  private onMiscUpdated(data: { hasComments: boolean, hasImages: boolean }) {
    this.filterHasImages = data.hasImages;
    this.filterHasComment = data.hasComments;
  }

  /**
   * Gets executed if the clear button
   * for a customer was pressed
   */
  public onCustomerClearClicked() {
    // Reset locations and current selected object
    this.customers = [];
    this.customerLocations = [];
    this.currentObjects = [];
  }

  public onObjectChanged(objects: Location[]) {
    this.currentObjects = objects;
  }

  public async onCustomerSelected(customers: Customer[]) {
    // Set Customers
    this.customers = customers;

    // Reset locations
    this.customerLocations = [];

    // Check if customer has data, if not return
    if (!this.customers || this.customers!.length <= 0) {
      return;
    }

    // Go through all selected customers, gather all location promises
    const customerPromises: Array<Promise<Customer>>
      = this.customers.map((element) => this.loadCustomerAction(element.id!));

    // Await all Customer Promises
    const resolvedCustomers: Customer[] = await Promise.all(customerPromises);

    // set current loaded locations
    this.customerLocations = ([] as Location[]).concat(...resolvedCustomers.map((customer: Customer) =>
      customer.locations));
  }

  /**
   * Checks if Filter is set to Individual
   */
  private get isIndividual(): boolean {
    return this.currentTimeFilter.length > 0
      && this.currentTimeFilter === 'individual';
  }

  /**
   * Checks if Filter is set to Weekly
   */
  private get isWeekly(): boolean {
    return this.currentTimeFilter.length > 0
      && this.currentTimeFilter === 'week';
  }

  /**
   * Checks if Filter is set to Daily
   */
  private get isDaily(): boolean {
    return this.currentTimeFilter.length > 0
      && this.currentTimeFilter === 'day';
  }

  /**
   * Checks if Filter is set to Monthly
   */
  private get isMonthly(): boolean {
    return this.currentTimeFilter.length > 0
      && this.currentTimeFilter === 'month';
  }

}
